import { clsx } from "clsx";
import { createElement as h, useRef, useEffect } from "react";
import { hydrateRoot as hydrate } from "react-dom/client";
export { h, hydrate };

export default ({ className, src }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current)
      if (ref.current.canPlayType("application/vnd.apple.mpegurl"))
        ref.current.src = src;
      else
        import("hls").then(({ default: HLS }) => {
          if (!HLS.isSupported()) return;
          const hls = new HLS();
          hls.loadSource(src);
          hls.attachMedia(ref.current);
        });
  }, []);

  return (
    <audio
      ref={ref}
      controls
      className={clsx(className, "filter contrast-150 w-full")}
    />
  );
};
